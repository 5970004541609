import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

interface DataProp {
  group: string;
  date: string;
  value: number;
}

interface TimelineChartProps {
  data: DataProp[];
  customColors?: { [key: string]: string };
}

const TimelineChart: React.FC<TimelineChartProps> = ({
  data,
  customColors,
}) => {
  const formattedData = data.reduce<{
    [key: string]: { x: number; y: number }[];
  }>((acc, { group, date, value }) => {
    const timestamp = new Date(date).getTime();
    if (!acc[group]) acc[group] = [];
    acc[group].push({ x: timestamp, y: value });
    return acc;
  }, {});

  const series = Object.keys(formattedData).map((group) => ({
    name: group,
    data: formattedData[group],
  }));

  const defaultColors: { [key: string]: string } = {
    Verbruik: "#C62828",
    Injectie: "#2E7D32",
  };

  const colors = series.map(
    (s) => customColors?.[s.name] || defaultColors[s.name] || "#808080",
  );

  const options: ApexOptions = {
    chart: {
      type: "area",
      height: 350,
      stacked: false,
      zoom: {
        enabled: true,
      },
    },
    colors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.6,
        opacityTo: 0.8,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
    xaxis: {
      type: "datetime",
      labels: {
        datetimeFormatter: {
          year: "yyyy",
          month: "MM-yyyy",
          day: "dd-MM-yyyy",
          hour: "HH:mm",
        },
      },
    },
    tooltip: {
      x: {
        format: "dd-MM-yyyy HH:mm",
      },
      style: {
        fontSize: "14px",
      },
      theme: "light", // Can be changed to "light" if preferred
      marker: {
        show: false, // Remove colored dots
      },
      y: {
        formatter: (value, { seriesIndex, w }) => {
          const color = w.config.colors[seriesIndex];
          return `<span style='color: ${color}; font-weight: bold;'>${value.toFixed(2)}</span>`;
        },
      },
    },
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={350}
      />
    </div>
  );
};

export default TimelineChart;
