import React, { useEffect, useState } from "react";
import NavBar from "../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../components/customer/NavigationCustomer";
import Footer from "../../../components/Footer/Footer";
import PageHero from "../../../components/Pagehero/PageHero";
import axios from "axios";
import { useStripe } from "@stripe/react-stripe-js";
import { getCookie } from "../../../cookieUtils";
import styles from "./PricesAndAbbos.module.css";

interface ProductVariant {
  id: string;
  name: string;
  price: number;
  stripePriceId: string;
}

interface Product {
  id: string;
  name: string;
  description: string;
  currency: string;
  interval: string;
  image: string;
  variants: ProductVariant[];
}

const ProductsOverview: React.FC = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [roleId, setRoleId] = useState<number | null>(null);
  const [selectedVariants, setSelectedVariants] = useState<{ [key: string]: string }>({});
  const stripe = useStripe();

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));
    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_DB_URL}/products`);
      const productsWithVariants = response.data.map((product: Product) => ({
        ...product,
        variants: product.variants.map((variant: ProductVariant) => ({
          ...variant,
          price: parseFloat(variant.price as unknown as string), // Ensure price is a number
        })),
      }));
      setProducts(productsWithVariants);
    } catch (error) {
      console.error("Error retrieving products:", error);
      setFetchError("An error occurred while retrieving products.");
    }
  };

  const handleVariantChange = (productId: string, variantId: string) => {
    setSelectedVariants((prev) => ({ ...prev, [productId]: variantId }));
  };

  const handleCheckout = async (product: Product) => {
    try {
      const storedUser = getCookie("user");
      if (!storedUser) {
        alert("User not logged in. Please log in to proceed.");
        return;
      }
      const user = JSON.parse(storedUser);

      const variantId = selectedVariants[product.id];
      if (!variantId) {
        alert("Please select a variant before proceeding.");
        return;
      }

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/create-checkout-session`, {
        productVariantId: variantId,
        userId: user.id,
      });

      const { sessionId } = response.data;

      if (stripe) {
        await stripe.redirectToCheckout({ sessionId });
      }
    } catch (error) {
      console.error("Error redirecting to checkout:", error);
      alert("Failed to initiate payment. Please try again.");
    }
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  // **✅ Productcategorieën filteren**
  const aircoProducts = products.filter((product) => product.name.includes("Airco Maintenance"));
  const boilerProducts = products.filter((product) => product.name.includes("Boiler Maintenance"));
  const solarProducts = products.filter((product) => product.name.includes("Solar Clean") || product.name.includes("Solar Safe") || product.name.includes("Solar EYE"));

  return (
    <>
      <NavBar toggleMenu={handleToggleMenu} />
      <PageHero>Prijzen en Abonnementen</PageHero>
      <div className={styles.pageContainer}>
        <NavigationCustomer isMenuVisible={isMenuVisible} toggleMenu={handleToggleMenu} />
        <div className={styles.contentContainer}>
          {fetchError ? (
            <div>
              <div>{fetchError}</div>
              <button onClick={fetchProducts}>Retry</button>
            </div>
          ) : !products.length ? (
            <div>Loading products, please wait...</div>
          ) : (
            <>
              {/* ✅ **Zonnepanelen reiniging sectie** */}
              <h2 className={styles.sectionTitle}>Zonnepanelen Reiniging</h2>
              <div className={styles.productsGrid}>
                {solarProducts.map((product) => (
                  <div key={product.id} className={styles.productCard}>
                    <img src={product.image} alt={product.name} className={styles.productImage} />
                    <h3>{product.name}</h3>
                    <p>{product.description}</p>

                    <select className={styles.variantSelect} onChange={(e) => handleVariantChange(product.id, e.target.value)} value={selectedVariants[product.id] || ""}>
                      <option value="" disabled>Select a variant</option>
                      {product.variants.map((variant) => (
                        <option key={variant.id} value={variant.id}>
                          {variant.name} - {product.currency} {variant.price.toFixed(2)}
                        </option>
                      ))}
                    </select>

                    <button className={styles.button} onClick={() => handleCheckout(product)}>Subscribe</button>
                  </div>
                ))}
              </div>

              {/* ✅ **Boiler onderhoud sectie** */}
              <h2 className={styles.sectionTitle}>Boiler Onderhoud</h2>
              <div className={styles.productsGrid}>
                {boilerProducts.map((product) => (
                  <div key={product.id} className={styles.productCard}>
                    <img src={product.image} alt={product.name} className={styles.productImage} />
                    <h3>{product.name}</h3>
                    <p>{product.description}</p>

                    <select className={styles.variantSelect} onChange={(e) => handleVariantChange(product.id, e.target.value)} value={selectedVariants[product.id] || ""}>
                      <option value="" disabled>Select a variant</option>
                      {product.variants.map((variant) => (
                        <option key={variant.id} value={variant.id}>
                          {variant.name} - {product.currency} {variant.price.toFixed(2)}
                        </option>
                      ))}
                    </select>

                    <button className={styles.button} onClick={() => handleCheckout(product)}>Subscribe</button>
                  </div>
                ))}
              </div>

              {/* ✅ **Airco onderhoud sectie** */}
              <h2 className={styles.sectionTitle}>Airco Onderhoud</h2>
              <div className={styles.productsGrid}>
                {aircoProducts.map((product) => (
                  <div key={product.id} className={styles.productCard}>
                    <img src={product.image} alt={product.name} className={styles.productImage} />
                    <h3>{product.name}</h3>
                    <p>{product.description}</p>

                    <select className={styles.variantSelect} onChange={(e) => handleVariantChange(product.id, e.target.value)} value={selectedVariants[product.id] || ""}>
                      <option value="" disabled>Select a variant</option>
                      {product.variants.map((variant) => (
                        <option key={variant.id} value={variant.id}>
                          {variant.name} - {product.currency} {variant.price.toFixed(2)}
                        </option>
                      ))}
                    </select>

                    <button className={styles.button} onClick={() => handleCheckout(product)}>Subscribe</button>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProductsOverview;


// import React, { useEffect, useState } from "react";
// import NavBar from "../../../components/Navigation/NavBar";
// import NavigationCustomer from "../../../components/customer/NavigationCustomer";
// import Footer from "../../../components/Footer/Footer";
// import PageHero from "../../../components/Pagehero/PageHero";
// import axios from "axios";
// import { useStripe } from "@stripe/react-stripe-js";
// import { getCookie } from "../../../cookieUtils";
// import styles from "./PricesAndAbbos.module.css";

// interface ProductVariant {
//   id: string;
//   name: string;
//   price: number;
//   stripePriceId: string;
// }

// interface Product {
//   id: string;
//   name: string;
//   description: string;
//   currency: string;
//   interval: string;
//   image: string;
//   variants: ProductVariant[];
// }

// const ProductsOverview: React.FC = () => {
//   const [isMenuVisible, setMenuVisible] = useState(false);
//   const [products, setProducts] = useState<Product[]>([]);
//   const [fetchError, setFetchError] = useState<string | null>(null);
//   const [roleId, setRoleId] = useState<number | null>(null);
//   const [selectedVariants, setSelectedVariants] = useState<{ [key: string]: string }>({});
//   const stripe = useStripe();

//   useEffect(() => {
//     const roleIdFromStorage = Number(getCookie("roleId"));
//     if (!isNaN(roleIdFromStorage)) {
//       setRoleId(roleIdFromStorage);
//     }
//     fetchProducts();
//   }, []);

//   const fetchProducts = async () => {
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_DB_URL}/products`);
//       const productsWithVariants = response.data.map((product: Product) => ({
//         ...product,
//         variants: product.variants.map((variant: ProductVariant) => ({
//           ...variant,
//           price: parseFloat(variant.price as unknown as string), // Ensure price is a number
//         })),
//       }));
//       setProducts(productsWithVariants);
//     } catch (error) {
//       console.error("Error retrieving products:", error);
//       setFetchError("An error occurred while retrieving products.");
//     }
//   };

//   const handleVariantChange = (productId: string, variantId: string) => {
//     setSelectedVariants((prev) => ({ ...prev, [productId]: variantId }));
//   };

//   const handleCheckout = async (product: Product) => {
//     try {
//       const storedUser = getCookie("user");
//       if (!storedUser) {
//         alert("User not logged in. Please log in to proceed.");
//         return;
//       }
//       const user = JSON.parse(storedUser);

//       const variantId = selectedVariants[product.id];
//       if (!variantId) {
//         alert("Please select a variant before proceeding.");
//         return;
//       }

//       const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/create-checkout-session`, {
//         productVariantId: variantId,
//         userId: user.id,
//       });

//       const { sessionId } = response.data;

//       if (stripe) {
//         await stripe.redirectToCheckout({ sessionId });
//       }
//     } catch (error) {
//       console.error("Error redirecting to checkout:", error);
//       alert("Failed to initiate payment. Please try again.");
//     }
//   };

//   const handleToggleMenu = () => {
//     setMenuVisible(!isMenuVisible);
//   };

//   return (
//     <>
//       <NavBar toggleMenu={handleToggleMenu} />
//       <PageHero>Prijzen en Abonnementen</PageHero>
//       <div className={styles.pageContainer}>
//         <NavigationCustomer
//           isMenuVisible={isMenuVisible}
//           toggleMenu={handleToggleMenu}
//         />
//         <div className={styles.contentContainer}>
//           {fetchError ? (
//             <div>
//               <div>{fetchError}</div>
//               <button onClick={fetchProducts}>Retry</button>
//             </div>
//           ) : !products.length ? (
//             <div>Loading products, please wait...</div>
//           ) : (
//             <div className={styles.productsGrid}>
//               {products.map((product) => (
//                 <div key={product.id} className={styles.productCard}>
//                   <img
//                     src={product.image}
//                     alt={product.name}
//                     className={styles.productImage}
//                     onError={(e) =>
//                       console.error("Error loading image:", e)
//                     }
//                   />
//                   <h2>{product.name}</h2>
//                   <p>{product.description}</p>

//                   {/* ✅ Variant Selection Dropdown */}
//                   <select
//                     className={styles.variantSelect}
//                     onChange={(e) => handleVariantChange(product.id, e.target.value)}
//                     value={selectedVariants[product.id] || ""}
//                   >
//                     <option value="" disabled>Select a variant</option>
//                     {product.variants.map((variant) => (
//                       <option key={variant.id} value={variant.id}>
//                         {variant.name} - {product.currency} {variant.price.toFixed(2)}
//                       </option>
//                     ))}
//                   </select>

//                   <button
//                     className={styles.button}
//                     onClick={() => handleCheckout(product)}
//                   >
//                     Subscribe
//                   </button>
//                 </div>
//               ))}
//             </div>
//           )}
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default ProductsOverview;



// import React, { useEffect, useState } from "react";
// import NavBar from "../../../components/Navigation/NavBar";
// import NavigationCustomer from "../../../components/customer/NavigationCustomer";
// import Footer from "../../../components/Footer/Footer";
// import PageHero from "../../../components/Pagehero/PageHero";
// import axios from "axios";
// import { useStripe } from "@stripe/react-stripe-js";
// import { getCookie } from "../../../cookieUtils";
// import styles from "./PricesAndAbbos.module.css";

// interface Product {
//   id: string;
//   name: string;
//   description: string;
//   price: number | string; // Allow price to be a number or a string initially
//   currency: string;
//   interval: string;
//   image: string;
// }

// const ProductsOverview: React.FC = () => {
//   const [isMenuVisible, setMenuVisible] = useState(false);
//   const [products, setProducts] = useState<Product[]>([]);
//   const [fetchError, setFetchError] = useState<string | null>(null);
//   const [roleId, setRoleId] = useState<number | null>(null);
//   const stripe = useStripe();

//   useEffect(() => {
//     const roleIdFromStorage = Number(getCookie("roleId"));
//     if (!isNaN(roleIdFromStorage)) {
//       setRoleId(roleIdFromStorage);
//     }
//     fetchProducts();
//   }, []);

//   const fetchProducts = async () => {
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_DB_URL}/products`);
//       const productsWithValidPrices = response.data.map((product: Product) => ({
//         ...product,
//         price: parseFloat(product.price as string), // Ensure price is a number
//       }));
//       setProducts(productsWithValidPrices);
//     } catch (error) {
//       console.error("Error retrieving products:", error);
//       setFetchError("An error occurred while retrieving products.");
//     }
//   };

//   const handleCheckout = async (product: Product) => {
//     try {
//       const storedUser = getCookie("user");
//       if (!storedUser) {
//         alert("User not logged in. Please log in to proceed.");
//         return;
//       }
//       const user = JSON.parse(storedUser);

//       const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/create-checkout-session`, {
//         products: [product],
//         userId: user.id,
//       });

//       const { sessionId } = response.data;

//       if (stripe) {
//         await stripe.redirectToCheckout({ sessionId });
//       }
//     } catch (error) {
//       console.error("Error redirecting to checkout:", error);
//       alert("Failed to initiate payment. Please try again.");
//     }
//   };

//   const handleToggleMenu = () => {
//     setMenuVisible(!isMenuVisible);
//   };

//   return (
//     <>
//       <NavBar toggleMenu={handleToggleMenu} />
//       <PageHero>Prijzen en Abonnementen</PageHero>
//       <div className={styles.pageContainer}>
//         <NavigationCustomer
//           isMenuVisible={isMenuVisible}
//           toggleMenu={handleToggleMenu}
//         />
//         <div className={styles.contentContainer}>
//           {fetchError ? (
//             <div>
//               <div>{fetchError}</div>
//               <button onClick={fetchProducts}>Retry</button>
//             </div>
//           ) : !products.length ? (
//             <div>Loading products, please wait...</div>
//           ) : (
//             <div className={styles.productsGrid}>
//               {products.map((product) => (
//                 <div key={product.id} className={styles.productCard}>
//                   <img
//                     src={product.image}
//                     alt={product.name}
//                     className={styles.productImage}
//                     onError={(e) =>
//                       console.error("Error loading image:", e)
//                     }
//                   />
//                   <h2>{product.name}</h2>
//                   <p>{product.description}</p>
//                   <p>
//                     {product.currency}{" "}
//                     {typeof product.price === "number"
//                       ? product.price.toFixed(2)
//                       : "0.00"}{" "}
//                     / {product.interval}
//                   </p>
//                   <button
//                     className={styles.button}
//                     onClick={() => handleCheckout(product)}
//                   >
//                     Subscribe
//                   </button>
//                 </div>
//               ))}
//             </div>
//           )}
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default ProductsOverview;





// import React, { useEffect, useState } from "react";
// import NavBar from "../../../components/Navigation/NavBar";
// import NavigationCustomer from "../../../components/customer/NavigationCustomer";
// import Footer from "../../../components/Footer/Footer";
// import PageHero from "../../../components/Pagehero/PageHero";
// import axios from "axios";
// import { useStripe } from "@stripe/react-stripe-js";
// import styles from "./PricesAndAbbos.module.css";

// interface Product {
//   id: string;
//   name: string;
//   description: string;
//   price: number | string; // Allow price to be a number or a string initially
//   currency: string;
//   interval: string;
//   image: string;
// }

// const ProductsOverview: React.FC = () => {
//   const [isMenuVisible, setMenuVisible] = useState(false);
//   const [products, setProducts] = useState<Product[]>([]);
//   const [fetchError, setFetchError] = useState<string | null>(null);
//   const stripe = useStripe();

//   const handleToggleMenu = () => {
//     setMenuVisible(!isMenuVisible);
//   };

//   const fetchProducts = async () => {
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_DB_URL}/products`);
//       const productsWithValidPrices = response.data.map((product: Product) => ({
//         ...product,
//         price: parseFloat(product.price as string), // Ensure price is a number
//       }));
//       setProducts(productsWithValidPrices);
//     } catch (error) {
//       console.error("Error retrieving products:", error);
//       setFetchError("An error occurred while retrieving products.");
//     }
//   };

//   const handleCheckout = async (product: Product) => {
//     try {
//       const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/create-checkout-session`, {
//         products: [product],
//       });

//       const { id } = response.data;

//       if (stripe) {
//         await stripe.redirectToCheckout({ sessionId: id });
//       }
//     } catch (error) {
//       console.error("Error redirecting to checkout:", error);
//       alert("Failed to initiate payment. Please try again.");
//     }
//   };

//   useEffect(() => {
//     fetchProducts();
//   }, []);

//   return (
//     <>
//       <NavBar toggleMenu={handleToggleMenu} />
//       <PageHero>Prijzen en Abonnementen</PageHero>
//       <div className={styles.pageContainer}>
//         <NavigationCustomer
//           isMenuVisible={isMenuVisible}
//           toggleMenu={handleToggleMenu}
//         />
//         <div className={styles.contentContainer}>
//           {fetchError ? (
//             <div>
//               <div>{fetchError}</div>
//               <button onClick={fetchProducts}>Retry</button>
//             </div>
//           ) : !products.length ? (
//             <div>Loading products, please wait...</div>
//           ) : (
//             <div className={styles.productsGrid}>
//               {products.map((product) => (
//                 <div key={product.id} className={styles.productCard}>
//                   <img
//                     src={product.image}
//                     alt={product.name}
//                     className={styles.productImage}
//                     onError={(e) =>
//                       console.error("Error loading image:", e)
//                     }
//                   />
//                   <h2>{product.name}</h2>
//                   <p>{product.description}</p>
//                   <p>
//                     {product.currency}{" "}
//                     {typeof product.price === "number"
//                       ? product.price.toFixed(2)
//                       : "0.00"}{" "}
//                     / {product.interval}
//                   </p>
//                   <button
//                     className={styles.button}
//                     onClick={() => handleCheckout(product)}
//                   >
//                     Subscribe
//                   </button>
//                 </div>
//               ))}
//             </div>
//           )}
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default ProductsOverview;




